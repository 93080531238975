import { useState, useEffect } from "react";
import proofs from "../proofs/proofs.json";
import {ethers} from "ethers"

export default function useProofs(address) {
  const [_proofs, set_proofs] = useState(undefined);

  useEffect(() => {
    if (address) {
      let _proofs = proofs && proofs.proofs ? proofs.proofs[ethers.utils.getAddress(address)] || [] : [];
      if (_proofs) {
        set_proofs(_proofs);
      }
    }
  }, [address]);

  return [_proofs];
}
