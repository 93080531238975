import { useState, useEffect } from 'react';
import Config from "../config/index.js"
import useProofs from './useProofs.js';
import useGasPrice from './useGasPrice.js';
import useAKCPrice from './useAKCPrice.js';

export default function useClaim(contract_blueBananaSerum, contract_claim, signer, provider, address) {
    const [proofs] = useProofs(address);
    const [gasPrice, fetchGasPrice] = useGasPrice(provider);
    const [akcPrice, fetchAKCPrice] = useAKCPrice(provider)

    const [hasClaimed, setHasClaimed] = useState();
    const [isOpen, setIsOpen] = useState();   
    
    const [error, setError] = useState();
    const [isClaiming, setIsClaiming] = useState();
    const [txHash, setTxHash] = useState();

    async function queryContracts() {
        if (contract_blueBananaSerum && contract_claim && address) {
            let _hasClaimed = await contract_claim.userToBBSClaims(address);
            setHasClaimed(_hasClaimed.gt(0));

            let _isOpen = await contract_claim.claimOpen();
            setIsOpen(_isOpen)

            fetchGasPrice();
            fetchAKCPrice();
        }
    }

    function setCorrectErrorMessage(e) {
        setError("An unexpected error occured and your transaction did not go through.");
        for (let error of Config.ERRORS.MESSAGES) {
            if (e.message.includes(error.error)) {
                let _msg = error.message;                
                setError(_msg)
                return;
            }
        }
    }

    async function claim() {
        let success = false;
        setError(undefined);

        if (contract_claim && signer) {    
            try {
                setIsClaiming(true);
                const tx = await contract_claim.connect(signer).claimBBS(proofs[0], proofs[1]);
                setTxHash(tx.hash);
                await tx.wait();               
                success = true
            } catch (e) {
                console.log(e)
                setCorrectErrorMessage(e);
            }

            await queryContracts();
            setIsClaiming(false);
        } else {
            alert("wallet not loaded");
        }

        return success;
    }

    function isDataLoaded() {
        return hasClaimed !== undefined &&
               isOpen!== undefined 
    }

    function canUserClaim() {
        return isDataLoaded() && !hasClaimed && proofs !== undefined && proofs[1] > 0;
    }

    function claimableAmount() {
        if (!isDataLoaded())
            return 0;
        if (!proofs || proofs.length === 0)
            return 0;
        if (hasClaimed)
            return 0;
        return proofs[1]
    }

    useEffect(() => {
        if (provider && contract_blueBananaSerum && contract_claim && address) {
            queryContracts();
            provider.on("block", _ => {
                console.log("block update")
                queryContracts();
            })
            return () => provider.off("block");
        }
    }, [provider, contract_blueBananaSerum, contract_claim, address])

    return [
        gasPrice,
        akcPrice,
        isDataLoaded,
        hasClaimed,
        isOpen,
        canUserClaim,
        claimableAmount,
        claim,
        isClaiming,
        txHash,
        error        
    ]
}