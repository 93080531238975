const Config = {
    DATA: {
        FAQ: [
            {
                question: "When am I eligible to receive the Blue Banana Serum?",
                mobileQuestion: "When am I <br/>eligible to receive <br/>the Blue Banana Serum?",
                answer: "Every Alpha Kongs holder that is staking their Alpha Kong NFTs in the ecosystem from 10 April until 18 April, will get the Blue Banana Serum airdrop, and thus the Omega Kong. You are able to claim the Blue Banana Serum on this webpage."
            },
            {
                question: "How do I get my Omega Kong NFT?",
                mobileQuestion: "How do I get <br/> my Omega Kong NFT?",
                answer: `
                If you’ve staked your Alpha Kongs within the ecosystem from 10 until 18 april, you’ll receive the serum. Your Alpha Kongs are thirsty and cannot wait to drink the Blue Banana Serum. After drinking it, it will burn and the incredible Omega Kong will appear in your wallet and on OpenSea. 
                <br/>
                <br/>
                So what if you decide to sell your Alpha Kong after you’ve received your BBS? Then you can’t create your Omega. <strong>You NEED at least one Alpha Kong NFT to drink the serum and get your Omega Kong(s)</strong>. Keep that in mind.                
                `
            },
            {
                question: "Is the Blue Banana Serum a NFT itself? ",
                mobileQuestion: "Is the Blue Banana Serum <br/> a NFT itself? ",
                answer: `Yes, it will be a collection apart from the Omega Kongs collection.`
            },
            {
                question: "Does my current Alpha Kongs NFT affect the rarity of my Omega?",
                mobileQuestion: "Does my current <br/>Alpha Kongs NFT affect<br/> the rarity of my Omega?",
                answer: "Nope, we want everybody to have equal chances of getting a rarer NFT, and an NFT from a higher Omega Kongs class, so this is gonna be completely random."
            },
            {
                question: "Are the Omega Kongs going to be a new collection? ",
                mobileQuestion: "Are the Omega<br/> Kongs going to be<br/> a new collection? ",
                answer: "Yes, we’re pushing the boundaries of the NFT-space with this collection with an incredible storyline. Stay tuned.."
            },
            {
                question: "When can I get my Omega Kong?",
                mobileQuestion: "When can I get my Omega Kong?",
                answer: `
                The Omega Kongs drop is projected to be in mid/late May, which will be anywhere from 18 to 25 May. The exact date will be shared beforehand.`
            },
            {
                question: "How many Blue Banana Serums do I get?",
                mobileQuestion: "How many Blue Banana <br/> Serums do I get?",
                answer: "For each staked Alpha Kong, you get a Blue Banana Serum NFT. One Blue Banana Serum NFT is used to get an Omega Kongs NFT."
            },                
        ],
        AKC: "0x45d0749A4E355495c675673D4f3B36A67aA08046"
    },
    CONTRACTS: {
        BBS: "BlueBananaSerum",
        KONG: "OmegaKongsClub",
        CLAIM: "BlueBananaSerumClaim",
        ISSUANCE: "OmegaKongsClubIssuanceContract"
    },
    NETWORK: {
        TARGET_CHAIN_ID: 1,
        CHAIN_ID_TO_NAME: {
            "1": "mainnet",
            "3": "ropsten",
            "4": "rinkeby",
            "42": "kovan",
            "1337": "localhost"
        },        
        INFURA_ID: "632b4850f79f4f7f9ba2468897bdcbc0",
        PORTIS_ID: "",
        FORTMATIC_ID: "",
        CHAIN_ID_TO_STABLE_COIN: {
            "1": "0x6b175474e89094c44da98b954eedeac495271d0f",
            "42": "0x4F96Fe3b7A6Cf9725f59d353F723c1bDb64CA6Aa"
        },
    },
    URLS: {
        etherscan: "https://etherscan.io/tx/",
        opensea: "https://opensea.io/collection/bluebananaserum"   
    },
    ERRORS: {
        MESSAGES: [
            {
                error: "MetaMask Tx Signature: User denied transaction signature",
                message: "Error: You rejected the transaction."
            },
            {
                error: "insufficient funds for gas * price + value",
                message: "Error: You don't have enough Ether in your wallet to pay for gas fees.",
            },
            {
                error: "Merkle Proof not valid",
                message: "Error: Your claim is invalid. Please contact the team for more info.",
            },
            {
                error: "BBS Already Claimed",
                message: "Error: All your serums are already claimed.",
            },
            {
                error: "Claim not opened",
                message: "Error: Claiming not opened yet.",
            }
        ]
    }
}

export default Config