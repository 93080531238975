import React from "react";

import useWeb3 from "../hooks/useWeb3";
import useClaim from "../hooks/useClaim";
import ClaimSerum from "../components/sections/ClaimSerum";

import "./index.css";

import FAQ from "../components/sections/FAQ";

export default function Index () {
    /** WEB 3 */
    const [
        provider,
        signer,
        address,
        contractBBS,
        contractOmegaKongs,
        contractClaim,
        contractIssuance,
        isConnected,
        isCorrectChain,
        tryAutoConnect,
        triedAutoConnecting,
        setTriedAutoConnecting,
        connect,
        isConnecting,
    ] = useWeb3();

    const [
        gasPrice,
        akcPrice,
        isDataLoaded,
        hasClaimed,
        isOpen,
        canUserClaim,
        claimableAmount,
        claim,
        isClaiming,
        txHash,
        error
    ] = useClaim(contractBBS, contractClaim, signer, provider, address)

    return (
        <div className="page-wrapper">
        <div className="container-d w-container">
           
            <div className="dashboard-wrapper">
                <ClaimSerum 
                    connect={connect}
                    isConnected={isConnected}
                    isConnecting={isConnecting}
                    address={address}
                    
                    gasPrice={gasPrice}
                    akcPrice={akcPrice}

                    isDataLoaded={isDataLoaded}
                    hasClaimed={hasClaimed}
                    isOpen={isOpen}
                    canUserClaim={canUserClaim}
                    claimableAmount={claimableAmount}
                    claim={claim}
                    isClaiming={isClaiming}
                    txHash={txHash}
                    error={error}
                />
                <FAQ />                
            </div>
        </div>
        <div className="footer">
            <a href="#" className="brand footer-brand w-nav-brand"><img src="images/Alpha_Kong_Club_logo_final_2Alpha_Kong_Club_logo_final_01.png" loading="lazy" alt="" className="logo-img"/></a>
            <div className="socials-wrap">
                <a href="http://Instagram.com/alphakongsclub" target="_blank" className="socials-link w-inline-block"><img src="images/Group-4.svg" loading="lazy" alt="" className="image-66"/></a>
                <a href="http://Twitter.com/alphakongsclub" target="_blank" className="socials-link w-inline-block"><img src="images/Group-3.svg" loading="lazy" alt="" className="image-66"/></a>
                <a href="https://discord.gg/alphakongsclub" target="_blank" className="socials-link _3 w-inline-block"><img src="images/Group-2.svg" loading="lazy" alt="" className="image-66"/></a>
            </div>
            <div className="p2 p-white">© 2022 Alpha Kongs Club | All Rights Reserved</div>
            <br/>
            <a href="https://www.alphakongsclub.com/terms-of-use-disclaimer" target="_blank" class="text-link white">Terms of use & Disclaimer</a>
        </div>
    </div>
    )
}