import { useState, } from "react";
import {ethers} from "ethers"

const twoDecimals = str => {
    //console.log(str)
    const split = str.split(".");
    //console.log(split)
    if (split.length > 1) {
      return split[0] + "." + split[1].substring(0, 2);
    } else {
      return str;
    }
};

export default function useGasPrice(provider) {
    const [gasPrice, setGasPrice] = useState(0);

    async function fetchGasPrice() {
        let _gasPrice = await provider.getGasPrice();
        if (_gasPrice._isBigNumber) {
            _gasPrice = ethers.utils.formatUnits(_gasPrice.toString(), "gwei")
        }
        setGasPrice(twoDecimals(_gasPrice.toString()))
    }

    return [ 
        gasPrice,
        fetchGasPrice
    ];
}
