import React, { useState, useEffect } from "react";
import Config from "../../config";

export default function FAQ({
}) {
    useEffect(()=>{
        window.Webflow && window.Webflow.destroy();
        window.Webflow && window.Webflow.ready();
        window.Webflow && window.Webflow.require('ix2');
        document.dispatchEvent(new Event('readystatechange'))
    });

    const splitTextForMobile = text => {
        let words = text.split(" ");
        
    }

    const [isOpen, setIsOpen] = useState({});

    const FAQItem = ({ question, mobQuestion, answer, index }) => {
        return (
            <div data-hover="false" data-delay="0" data-w-id="62c351f0-6a80-f4b1-9142-27edc13dbd99" className="dropdown w-dropdown">
                <div data-w-id="62c351f0-6a80-f4b1-9142-27edc13dbd9a" className="dropdown-toggle w-dropdown-toggle" onClick={() => {
                    if (isOpen[index]) {
                        setIsOpen(open => ({ ...open, [index]: false }));
                    } else {
                        setIsOpen(open => ({ ...open, [index]: true }));
                    }                     
                }}>
                    <img src="images/X_Icon.svg" loading="lazy" 
                        style={{
                            "WebkitTransform": "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(45deg) skew(0, 0)",
                            "MozTransform": "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(45deg) skew(0, 0)",
                            "msTransform": "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(45deg) skew(0, 0)",
                            "transform": "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(45deg) skew(0, 0)"
                        }} alt="" className="image-65"/>
                    <div className="h3 all-caps desk" dangerouslySetInnerHTML={{ __html: question }} style={{color: "#fffc00"}}></div>
                    <div className="h3 all-caps mob" dangerouslySetInnerHTML={{ __html: mobQuestion }}></div>
                </div>
                
                <nav style={{display: isOpen[index] ? "block" : "none", height: isOpen[index] ? "auto" : "0px", opacity: isOpen[index] ? "1" : "0", width: isOpen[index] ? "1121.76" : "auto"}} className="dropdown-list w-dropdown-list">
                    <p className="p2-2 opacity-80 padding"  dangerouslySetInnerHTML={{ __html: answer }}>                     
                    </p>
                </nav>
            </div>
        )
    }

    return (
        <div className="vert-cent" id="faq">
            <h2 className="h3 h-white left l">FREQUENTLY ASKED<br/>QUESTIONS</h2>
            <div className="faq-wrap">
                {
                    Config.DATA.FAQ.map((f,i) => <FAQItem key={i} question={f.question} mobQuestion={f.mobileQuestion} answer={f.answer} index={i} />)
                }

                {/*
                <div data-hover="false" data-delay="0" data-w-id="62c351f0-6a80-f4b1-9142-27edc13dbd99" className="dropdown w-dropdown">
                    <div data-w-id="62c351f0-6a80-f4b1-9142-27edc13dbd9a" className="dropdown-toggle w-dropdown-toggle"><img src="images/X_Icon.svg" loading="lazy" 
                        style={{
                            "WebkitTransform": "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(45deg) skew(0, 0)",
                            "MozTransform": "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(45deg) skew(0, 0)",
                            "msTransform": "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(45deg) skew(0, 0)",
                            "transform": "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(45deg) skew(0, 0)"
                        }} alt="" className="image-65"/>
                        <div className="h3 all-caps desk">How much daily yield do I earn?</div>
                        <div className="h3 all-caps mob">How much daily yield <br/>do I earn?</div>
                    </div>
                    
                    <nav style={{display: "none", height: "0px", opacity: "0"}} className="dropdown-list w-dropdown-list">
                        <p className="p2-2 opacity-80 padding">Each tribe item has a different daily yield percentage. A lab yields you 2.5% per day, excluding boosts that you can get to get more  daily yield. The more tribe items you have, the more passive yield you earn. Unlock boosts to get earning bonuses of up to 40%. Yield is paid out live in your AKC dashboard and refreshes live, so you do not have to wait a day to get your Alpha Coins. You can always buy and sell your Alpha Coins for other cryptocurrencies on Uniswap. Also, don’t forget to invite your friends to earn free Alpha Coins.</p>
                    </nav>
                </div>

                <div data-hover="false" data-delay="0" data-w-id="62c351f0-6a80-f4b1-9142-27edc13dbda5" className="dropdown w-dropdown">
                    <div data-w-id="62c351f0-6a80-f4b1-9142-27edc13dbda6" className="dropdown-toggle w-dropdown-toggle"><img src="images/X_Icon.svg" loading="lazy" 
                    style={{
                        "WebkitTransform": "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(45deg) skew(0, 0)",
                        "MozTransform": "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(45deg) skew(0, 0)",
                        "msTransform": "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(45deg) skew(0, 0)",
                        "transform": "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(45deg) skew(0, 0)"
                    }} alt="" className="image-65"/>
                        <div className="h3 all-caps desk">What’s a tribe item, such as a Lab?</div>
                        <div className="h3 all-caps mob">What’s a tribe item, <br/>such as a Lab?</div>
                    </div>
                    <nav style={{display: "none", height: "0px", opacity: "0"}} className="dropdown-list w-dropdown-list">
                        <p className="p2-2 opacity-80 padding">Each tribe has a specific item that they use, such as scientists that have a lab. Tribe items generate a daily yield in Alpha Coins for as long as you have the tribe item. They start generating Alpha Coins from the moment that you have a tribe item.</p>
                    </nav>
                </div>

                <div data-hover="false" data-delay="0" data-w-id="62c351f0-6a80-f4b1-9142-27edc13dbdb1" className="dropdown w-dropdown">
                    <div data-w-id="62c351f0-6a80-f4b1-9142-27edc13dbdb2" className="dropdown-toggle w-dropdown-toggle"><img src="images/X_Icon.svg" loading="lazy" style={{
                        "WebkitTransform": "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(45deg) skew(0, 0)",
                        "MozTransform": "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(45deg) skew(0, 0)",
                        "msTransform": "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(45deg) skew(0, 0)",
                        "transform": "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(45deg) skew(0, 0)"
                    }} alt="" className="image-65"/>
                        <div className="h3 all-caps desk">Do I need an Alpha Kong NFT <br/>to start earning Alpha Coins?</div>
                        <div className="h3 all-caps mob">Do I need an Alpha <br/>Kong NFT to start <br/>earning Alpha Coins?</div>
                    </div>
                    <nav style={{display: "none", height: "0px", opacity: "0"}} className="dropdown-list w-dropdown-list">
                        <p className="p2-2 opacity-80 padding">
                        Nope, you don’t! Anyone that’s excited about the AKC ecosystem and what’s to get involved, can join a tribe and start earning yield. We do this because the more support we get, the more the ecosystem will expand. However, staking your Alpha Kong NFT in a tribe item does reward you with greater staking rewards.</p>
                    </nav>
                </div>

                <div data-hover="false" data-delay="0" data-w-id="62c351f0-6a80-f4b1-9142-27edc13dbdc1" className="dropdown w-dropdown">
                    <div data-w-id="62c351f0-6a80-f4b1-9142-27edc13dbdc2" className="dropdown-toggle w-dropdown-toggle"><img src="images/X_Icon.svg" loading="lazy" style={{
                        "WebkitTransform": "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(45deg) skew(0, 0)",
                        "MozTransform": "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(45deg) skew(0, 0)",
                        "msTransform": "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(45deg) skew(0, 0)",
                        "transform": "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(45deg) skew(0, 0)"
                    }} alt="" className="image-65"/>
                        <div className="h3 all-caps desk">What’s a tribe?</div>
                        <div className="h3 all-caps mob">What’s a tribe?</div>
                    </div>
                    <nav style={{display: "none", height: "0px", opacity: "0"}} className="dropdown-list w-dropdown-list">
                        <p className="p2-2 opacity-80 padding">
                        A tribe is a group of Alpha Kongs with a unique personality, such as the Explorers. They collaborate in order to expand the AKC ecosystem. Each tribe has a tribe item that generates daily earnings. You do not need to have an Alpha Kong NFT to get a tribe item.</p>
                    </nav>
                </div>

                <div data-hover="false" data-delay="0" data-w-id="62c351f0-6a80-f4b1-9142-27edc13dbdcb" className="dropdown w-dropdown">
                    <div data-w-id="62c351f0-6a80-f4b1-9142-27edc13dbdcc" className="dropdown-toggle w-dropdown-toggle"><img src="images/X_Icon.svg" loading="lazy" style={{
                        "WebkitTransform": "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(45deg) skew(0, 0)",
                        "MozTransform": "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(45deg) skew(0, 0)",
                        "msTransform": "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(45deg) skew(0, 0)",
                        "transform": "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(45deg) skew(0, 0)"
                    }} alt="" className="image-65"/>
                        <div className="h3 all-caps desk">How can I buy Alpha Coins?</div>
                        <div className="h3 all-caps mob">How can I buy <br/>Alpha Coins?</div>
                    </div>
                    <nav style={{display: "none", height: "0px", opacity: "0"}} className="dropdown-list w-dropdown-list">
                        <p className="p2-2 opacity-80 padding">
                        You can buy and sell Alpha Coins on here Uniswap at any time. A selling tax of 20% will be applied to stabilise the price of the Alpha Coins in the first few weeks. This will gradually decrease over the first few weeks to 10%. We want to prevent spikes in prices as much as possible.</p>
                    </nav>
                </div>

                <div data-hover="false" data-delay="0" data-w-id="62c351f0-6a80-f4b1-9142-27edc13dbdd7" className="dropdown w-dropdown">
                    <div data-w-id="62c351f0-6a80-f4b1-9142-27edc13dbdd8" className="dropdown-toggle w-dropdown-toggle"><img src="images/X_Icon.svg" loading="lazy" style={{
                        "WebkitTransform": "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(45deg) skew(0, 0)",
                        "MozTransform": "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(45deg) skew(0, 0)",
                        "msTransform": "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(45deg) skew(0, 0)",
                        "transform": "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(45deg) skew(0, 0)"
                    }} alt="" className="image-65"/>
                        <div className="h3 all-caps desk">Can I own more tribe items?</div>
                        <div className="h3 all-caps mob">Can I own more <br/>tribe items?</div>
                    </div>
                    <nav style={{display: "none", height: "0px", opacity: "0"}} className="dropdown-list w-dropdown-list">
                        <p className="p2-2 opacity-80 padding">
                        Yes, you can. You generate more Alpha Coins on a daily basis by getting more items.</p>
                    </nav>
                </div>

                <div data-hover="false" data-delay="0" data-w-id="62c351f0-6a80-f4b1-9142-27edc13dbde3" className="dropdown w-dropdown">
                    <div data-w-id="62c351f0-6a80-f4b1-9142-27edc13dbde4" className="dropdown-toggle w-dropdown-toggle"><img src="images/X_Icon.svg" loading="lazy" style={{
                        "WebkitTransform": "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(45deg) skew(0, 0)",
                        "MozTransform": "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(45deg) skew(0, 0)",
                        "msTransform": "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(45deg) skew(0, 0)",
                        "transform": "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(45deg) skew(0, 0)"
                    }} alt="" className="image-65"/>
                        <div className="h3 all-caps desk">Can I get tribe items from <br/>more tribes, or just from one tribe?</div>
                        <div className="h3 all-caps mob">Can I get tribe items from <br/>more tribes, or just <br/>from one tribe?</div>
                    </div>
                    <nav style={{display: "none", height: "0px", opacity: "0"}} className="dropdown-list w-dropdown-list">
                        <p className="p2-2 opacity-80 padding">
                        Yes you can own multiple tribe items from different tribes. If you feel home in multiple tribes, feel free to join them and get multiple tribe items.</p>
                    </nav>
                </div>

                <div data-hover="false" data-delay="0" data-w-id="62c351f0-6a80-f4b1-9142-27edc13dbdf3" className="dropdown w-dropdown">
                    <div data-w-id="62c351f0-6a80-f4b1-9142-27edc13dbdf4" className="dropdown-toggle w-dropdown-toggle"><img src="images/X_Icon.svg" loading="lazy" style={{
                        "WebkitTransform": "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(45deg) skew(0, 0)",
                        "MozTransform": "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(45deg) skew(0, 0)",
                        "msTransform": "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(45deg) skew(0, 0)",
                        "transform": "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(45deg) skew(0, 0)"
                    }} alt="" className="image-65"/>
                        <div className="h3 all-caps desk">How do tokenomics work?</div>
                        <div className="h3 all-caps mob">How do tokenomics <br/>work?</div>
                    </div>
                    <nav style={{display: "none", height: "0px", opacity: "0"}} className="dropdown-list w-dropdown-list">
                        <p className="p2-2 opacity-80 padding">
                        Our team consists of economists, tokenomics and DeFi experts with much experience in their craft. Together, we’ve developed the AKC ecosystem to become a success. <br/>- 72% of the Alpha Coins from tribe item purchases go to the reward pool. This is used and saved up for daily rewards, staking rewards and all earning bonuses that are unlocked by members. <br/>2: 16% is used for the following aspects: <br/>- development of the platform. We are working with experts constantly to make new utilities at a fast pace. <br/>- buying the floor: we buy alpha coins with a cold wallet that cannot be accessed  &gt; or we buy alpha coins and burn them <br/>- affiliate rewards to members that invite their friends.   <br/>- airdrops and giveaways: as you know, we like playing games and rewarding the community. We’re using a small cut to do fun giveaways and airdrops for the community. <br/>- earnings for the AKC team <br/>3: 12% goes to the liquidity pool, which provides more stability to the price of Alpha Coins (Alpha Coins and eth 50/50) <br/>4: Alpha Kongs Club OpenSea commissions We’re also using 30% of the OS commissions for the following things: <br/>- 50% is used for the AKC liquidity pool <br/>- 50% is used to buy alpha coins (buy the floor) <br/>Is Tokenomics a new term for you? <br/>Tokenomics basically means the economics of a crypto token. If this is a new topic for you, we recommend learning more about it, especially if you are new in the cryptocurrency and NFT space. There are many credible sources on Google, and informative videos on Youtube.</p>
                    </nav>
                </div>

                <div data-hover="false" data-delay="0" data-w-id="62c351f0-6a80-f4b1-9142-27edc13dbe19" className="dropdown w-dropdown">
                    <div data-w-id="62c351f0-6a80-f4b1-9142-27edc13dbe1a" className="dropdown-toggle w-dropdown-toggle"><img src="images/X_Icon.svg" loading="lazy" style={{
                        "WebkitTransform": "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(45deg) skew(0, 0)",
                        "MozTransform": "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(45deg) skew(0, 0)",
                        "msTransform": "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(45deg) skew(0, 0)",
                        "transform": "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(45deg) skew(0, 0)"
                    }} alt="" className="image-65"/>
                        <div className="h3 all-caps desk">What’s the price of one Alpha Coin?</div>
                        <div className="h3 all-caps mob">What’s the price of <br/>one Alpha Coin?</div>
                    </div>
                    <nav style={{display: "none", height: "0px", opacity: "0"}} className="dropdown-list w-dropdown-list">
                        <p className="p2-2 opacity-80 padding">
                        The price of one Alpha Coin is dependent upon the supply and demand of the coins. It can vary, just like other utility tokens in the market. <br/>It’s tradeable on Uniswap, so you can see the exact price here: <br/>Can more Alpha Coins ever get created? <br/>No, there is no possibility of creating more Alpha Coins, this is encoded in the contract. <br/>1: What’s the contract of Alpha Coins? <br/>The contract is:  <br/>2: On which chain is the ecosystem built? <br/>Ethereum. Most people use ethereum, so it’s most user friendly. The contract will be optimised to minimise gas fees. There are other chains that have lesser gas fees, but user friendliness and ease of use is more important to us. <br/>6: What’s the benefit of owning Alpha Kong NFTs in the AKC ecosystem? <br/>You get increased staking rewards by staking your Alpha Kong in a tribe item. <br/>Also, you can always stake your Alpha Kong NFT for free in the capsule, for a passive yield in Alpha Coins. This is less than staking them in a tribe item. <br/>Example: If you have 10 labs, all of them yield 5% more daily earnings if you stake your Alpha Kong NFT in them. <br/>Can I always buy and sell Alpha Coins for other cryptocurrencies?<br/>8: What are the current, and the upcoming utilities <br/>Anyone that joins a tribe gets to participate in collaborative efforts to expand the AKC ecosystem with new utilities. Participate in games with all tribes together, and per tribe, to grow the AKC ecosystem. <br/>You can stake your Alpha Kongs NFT in your tribe to boost daily earnings, or stake it for free for daily Alpha Coins. <br/>The next steps is creating a DAO with voting rights in the AKC ecosystem. In the first few weeks, we work together as a community to decide upon the next utilities for the ecosystem. <br/>We believe working together as one aligns our vision as a community to build what we want to see in the future.</p>
                    </nav>
                </div>

                    <div data-hover="false" data-delay="0" data-w-id="62c351f0-6a80-f4b1-9142-27edc13dbe47" className="dropdown w-dropdown">
                    <div data-w-id="62c351f0-6a80-f4b1-9142-27edc13dbe48" className="dropdown-toggle w-dropdown-toggle"><img src="images/X_Icon.svg" loading="lazy" style={{
                        "WebkitTransform": "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(45deg) skew(0, 0)",
                        "MozTransform": "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(45deg) skew(0, 0)",
                        "msTransform": "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(45deg) skew(0, 0)",
                        "transform": "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(45deg) skew(0, 0)"
                    }} alt="" className="image-65"/>
                        <div className="h3 all-caps desk">How do I stake my Alpha Kong for free?</div>
                        <div className="h3 all-caps mob">How do I stake my <br/>Alpha Kong for free?</div>
                    </div>
                    <nav style={{display: "none", height: "0px", opacity: "0"}} className="dropdown-list w-dropdown-list">
                        <p className="p2-2 opacity-80 padding">Any Alpha Kong NFT holder can stake their Alpha Kong NFT in a capsule for free. Doing so rewards them with free Alpha Coins on a daily basis.</p>
                    </nav>
                </div>

                <div data-hover="false" data-delay="0" data-w-id="62c351f0-6a80-f4b1-9142-27edc13dbe53" className="dropdown w-dropdown">
                    <div data-w-id="62c351f0-6a80-f4b1-9142-27edc13dbe54" className="dropdown-toggle w-dropdown-toggle"><img src="images/X_Icon.svg" loading="lazy" style={{
                        "WebkitTransform": "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(45deg) skew(0, 0)",
                        "MozTransform": "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(45deg) skew(0, 0)",
                        "msTransform": "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(45deg) skew(0, 0)",
                        "transform": "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(45deg) skew(0, 0)"
                    }} alt="" className="image-65"/>
                        <div className="h3 all-caps desk">How do I stake my Alpha Kong NFT in the item of a tribe?</div>
                        <div className="h3 all-caps mob">How do I stake my Alpha <br/>Kong NFT in the <br/>item of a tribe?</div>
                    </div>
                    <nav style={{display: "none", height: "0px", opacity: "0"}} className="dropdown-list w-dropdown-list">
                        <p className="p2-2 opacity-80 padding">You can stake your Alpha Kong NFT in the item if your tribe for more rewards. You do this in your dashboard.</p>
                    </nav>
                </div>*/}
            </div>
        </div>
    )
}