import React, { useState } from "react";
import { ethers } from "ethers";
import ConnectWalletButton1 from "../components/ConnectWalletButton1";
import Config from "../../config";
import Loader from 'react-loaders'

export default function ClaimSerum({
    connect,
    isConnected,
    isConnecting,
    address,
    
    gasPrice,
    akcPrice,

    isDataLoaded,
    hasClaimed,
    isOpen,
    canUserClaim,
    claimableAmount,
    claim,
    isClaiming,
    txHash,
    error,
}) {

    return (
        <div id="dashboard" className="dashboard-div">
                <div className="hor-left betw vert-mob">
                    <div className="vert-left">
                        <h1 className="h3 h-white left">CLAIM YOUR BLUE BANANA SERUM</h1>
                        <div className="tb-14" style={{fontSize: "16px"}}>
                                Each Blue Banana Serum gets you one Omega Kong NFT, you need at least one Alpha Kong NFT to use the blue banana serum. 
                                
                                The Omega Kongs drop is in mid/late may, so you cannot drink the blue banana serum yet. 
                                <br/>
                                <br/>
                                Once you drink the Blue Banana Serum, it gets burned and you get your Omega Kong(s). 
                               
                                You get one Blue Banana Serum for each Alpha Kong NFT that you staked from 10 April to 18 April.                               
                                
                                You can already stake your Blue Banana Serum in the capsule to get 1 Alpha Coin per day.                            
                               
                                The BB Serum is a collection in itself, so it’s tradeable on the open market. 
                        </div>
                    </div>
                </div>
                <div className="grid-wrapper">
                    <div className="w-layout-grid grid-4">
                        <div className="claim-col">
                            <img src="https://storage.googleapis.com/alphakongclub/bluebananaserum/OMEGA_Pod.gif" alt="Loading" title="Loading" height="500" />
                        </div>
                        <div className="claim-col">
                            <h1 className="claim-title">Claim your Serum here</h1>
                            {
                                isDataLoaded() ?
                                 <p className="claim-text">
                                    {
                                        isOpen && !hasClaimed && !canUserClaim() && `Unfortuntately you are not eligible for the Blue Banana Serum claim. 
                                                                        If you want to get your hands on an Omega kong make sure to get your serum
                                                                        from opensea using the button below.`
                                    }
                                    {
                                        isOpen && hasClaimed && !canUserClaim() && `Thank you for claiming your Blue Banana Serum(s). You will be able to let your
                                                                        Alpha Kong drink the serum(s) in mid/late may in exchange for an Omega Kong.`
                                    }
                                    {
                                        isOpen && !hasClaimed && canUserClaim() && `Congratulations, you are eligible for the Blue Banana Serum claim.
                                                                        Please press the button below to claim your Blue Banana Serums to your wallet.
                                                                        After having claimed your Blue Banana Serum(s) the opensea link will appear.`
                                    }
                                    {
                                        !isOpen && `Claiming Blue Banana Serum starts at 20:00pm CEST April 20th 2022.`
                                    }
                                </p>
                                :
                                <p className="claim-text">
                                    Connect your wallet below to claim your Blue Banana Serum.
                                </p>
                            }
                            <div id="w-node-aaadab01-af3e-3daa-91b8-d69e0309f5bb-a8baebfa" className="grey-div">
                                <div className="tb-14 all-caps" style={{fontSize: "16px"}}>Gas Price</div>
                                <h2 className="h3 h-white" style={{color: "#fffc00"}}>{gasPrice}</h2>                                
                            </div>
                            <div id="w-node-aaadab01-af3e-3daa-91b8-d69e0309f5bb-a8baebfa" className="grey-div">
                                <div className="tb-14 all-caps" style={{fontSize: "16px"}}>AKC Price</div>
                                <h2 className="h3 h-white" style={{color: "#fffc00"}}>{akcPrice}</h2>                                
                            </div>
                            <div id="w-node-aaadab01-af3e-3daa-91b8-d69e0309f5bb-a8baebfa" className="grey-div">
                                <div className="tb-14 all-caps" style={{fontSize: "16px"}}>CLAIMABLE SERUMS</div>
                                <h2 className="h3 h-white" style={{color: "#fffc00"}}>{claimableAmount()}</h2>                                
                            </div>

                            {
                                !isConnected &&
                                <ConnectWalletButton1
                                    connect={connect}
                                    isConnected={isConnected}
                                    isConnecting={isConnecting}
                                    address={address}
                                />
                            }

                            {
                                isConnected && !isDataLoaded() &&
                                <div className="button _2nd db w-button">Loading</div>
                            }

                            {
                                isDataLoaded() &&
                                <>
                                    {
                                        isOpen ?
                                        <>
                                            {
                                                canUserClaim() ?
                                                <>
                                                    {
                                                        !isClaiming &&
                                                        <div className="button _2nd db w-button" onClick={claim}>Claim My Serums</div>
                                                    }
                                                    {
                                                        isClaiming && !error &&
                                                        <div className="button _2nd db w-button" onClick={() => {
                                                            if (txHash)
                                                                window.open(Config.URLS.etherscan + txHash, "_blank");
                                                        }}>{txHash ? "View Transaction" : "Please Confirm"}</div>
                                                    }
                                                    {
                                                        error &&
                                                        <p className="error-text">
                                                            {error}
                                                        </p>
                                                    }
                                                </>
                                                :
                                                <>
                                                    <div className="button _2nd db w-button" onClick={() => {                                                            
                                                            window.open(Config.URLS.opensea, "_blank");
                                                    }}>View on opensea</div>
                                                </>
                                            }
                                        </>
                                        :
                                        <>
                                            <div className="button _2nd db w-button">Claiming not opened</div>
                                        </>
                                    }
                                
                                </>
                            }                      
                        </div>                                                 
                    </div>
                </div>
        </div>
    )
}