import { useState, } from "react";
import {ethers} from "ethers"
import Config from "../config"
import { Token, WETH, Fetcher, Trade, Route, TokenAmount, TradeType } from '@uniswap/sdk'

const twoDecimals = str => {
    //console.log(str)
    const split = str.split(".");
    //console.log(split)
    if (split.length > 1) {
      return split[0] + "." + split[1].substring(0, 2);
    } else {
      return str;
    }
};

export default function useAKCPrice(provider) {
    const [akcPrice, setAkcPrice] = useState(0);

    async function fetchAKCPrice() {
        if (Config.NETWORK.TARGET_CHAIN_ID === 1) {
            let AKCCoin = Config.DATA.AKC
        
            const AKCToken = new Token(Config.NETWORK.TARGET_CHAIN_ID, AKCCoin, 18)
            const akcPair = await Fetcher.fetchPairData(AKCToken, WETH[AKCToken.chainId], provider)    
            const akcRoute = new Route([akcPair], WETH[AKCToken.chainId])    
            const akcTrade = new Trade(akcRoute, new TokenAmount(WETH[AKCToken.chainId], '1000000000000000000'), TradeType.EXACT_INPUT)

            const DAI = new Token(Config.NETWORK.TARGET_CHAIN_ID, Config.NETWORK.CHAIN_ID_TO_STABLE_COIN[Config.NETWORK.TARGET_CHAIN_ID], 18)
            const pair = await Fetcher.fetchPairData(DAI, WETH[DAI.chainId], provider)    
            const route = new Route([pair], WETH[DAI.chainId])    
            const trade = new Trade(route, new TokenAmount(WETH[DAI.chainId], '1000000000000000000'), TradeType.EXACT_INPUT)

            const ethToAkc = akcTrade.executionPrice
            const ethToUsd = trade.executionPrice;
            const AkcToUsd = ethToUsd.divide(ethToAkc)
            setAkcPrice(AkcToUsd.toSignificant(2))     
        } else {
            setAkcPrice("10")
        }
    }

    return [ 
        akcPrice,
        fetchAKCPrice
    ];
}
