import React from "react"

export default function ConnectWalletButton1({
    connect,
    isConnected,
    isConnecting,
    address,
}) {
    return (
        <>
            {
                !isConnected && !isConnecting &&
                <span onClick={connect} className="button _2nd db w-button">connect my wallet</span>
            }
            {
                !isConnected && isConnecting &&
                <span className="button _2nd db w-button">Please confirm the connection.</span>
            }
            {
                isConnected && address &&
                <span className="button _3rd yellow marg-left w-button">{ address.slice(0, 20) } ...</span>
            }
        </>
        
    )
}