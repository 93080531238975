import React from 'react';
import Index from "./pages/index.jsx"

function App() {
  return (
    <div id="appElement">
       <Index />
    </div>
  );
}

export default App;
